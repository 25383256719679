import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextContainer from "../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  @media only screen and (min-width: 1200px) {
    margin: 0em 2em !important;
  }
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="About HFE Mentors" keywords={[`host family english`, `hfe`]} />
    <Hero
      title="ABOUT HFE MENTORS"
      dimmed
      img="https://res.cloudinary.com/mystay-international/image/upload/c_scale,w_1400/v1555552499/Host%20Family%20English/hosting-overview-10-1024x534.jpg"
    />
    <Grid
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <TextContainer>
        <h2>Working with Select Partners</h2>
        <p>
          At HFE we work closely with select Homestay Organizations and
          Education Providers to upskill their international student homestay
          hosting program so that they can build better businesses through
          better products and outcomes for their international students wanting
          to fast track their English learning.
        </p>
        <h3 style={{ textAlign: "center" }}>
          Become a Mentor with Host Family English
        </h3>
        <ReactPlayer
          url="https://homestaynetwork.wistia.com/medias/8qym0w1nxj"
          wistiaConfig={{
            options: {
              videoFoam: true,
            },
          }}
          width="100%"
          height="auto"
        />
        <h3>Advanced Training for your Host Families</h3>
        <p>
          Hosting an International student is a rewarding experience but not
          without its challenges. Students can feel alone, homesick and confused
          and this can result in them become withdrawn and not getting the most
          educationally and culturally from their experience.
        </p>
        <p>
          By learning the techniques of HFE mentoring, your Host Families will
          be able to identify issues and act in a natural but decisive way to
          help the student overcome their challenges and get the very best from
          the experience.
        </p>
        <h3>Designed by a Mentoring Expert</h3>
        <p>
          Host Family English has engaged a leading expert in the field of
          mentoring to produce a globally recognized series of 7 video tutorials
          (and accompanying Mentor Knowledge Checks) that will lead your Host
          Families through a series of scenarios to prepare them for the
          homestay of their international guest.
        </p>
        <p>
          Over the course of the training program, HFE Mentors will learn about
          the developmental stages that their student guest will be going
          through – this ‘insight’ into the student experience is to help them
          to build empathy, as well as to better understand where and how their
          support might be required by the student. A range of mentoring tools
          and practices will be explained and demonstrated, and they will be
          provided with support materials and resources, such as icebreakers,
          frameworks and models to aid them in their own journey as a Mentor
          Host. It also addresses common questions that the new Mentors may
          have, helps them to identify ways to support the students’ learning,
          as well as to facilitate other ways to help build their student
          guests’ confidence to actively use English in everyday life.
        </p>
        <p>
          Upon successful completion of the course the Host Family will receive
          a certificate of achievement, which allows them to participate in the
          program.
        </p>
        <h3>Growing your Students’ Confidence Outside of the Classroom</h3>
        <p>
          All Mentors will be licensed through approved Hosts of our business
          partners either being English Colleges, other kinds of Education
          Institutions and/or authorized Homestay Operators.
        </p>
        <p>
          HFE Hosts are not considered teachers or tutors, they will be asked to
          formally and informally mentor the student in Conversational English
          during the course of the stay. The HFE Mentor role involves helping
          your student to apply what they have learned at school or university,
          within other environments, both in the home, but also in other areas
          of daily life. They are not teaching the student; rather they are
          providing opportunities and support for the student to grow in
          confidence outside of the classroom.
        </p>
      </TextContainer>
    </Grid>
  </Layout>
)

export default IndexPage
